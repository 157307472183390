<template>
  <div class="DataTable">
    <div>
      <div v-if="gridMobile && isMobile" class="mobile">
        <q-card class="card-header">
          <q-card-section class="q-mx-lg">
            <q-input
              type="text"
              label="Filtro"
              style="width: 100%"
              v-model="filter"
              clearable
              v-if="filterData"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
            <span v-if="title" class="text h6">
              {{ title }}
            </span>
          </q-card-section>
          <q-card-section>
            <q-btn
              class="on-right"
              v-if="actionButtonLabel"
              :label="actionButtonLabel"
              :color="actionButtonColor"
              :icon-right="actionButtonIcon"
              @click="actionButtonClick"
              flat
              outline
            />
          </q-card-section>
        </q-card>
        <q-card
          class="q-mt-md"
          v-for="(row, index) in data"
          :key="index"
          @click.native="rowClick(row)"
        >
          <q-card-section>
            <div class="row" v-for="(column, index) in columns" :key="index">
              <div class="col text-bold">{{ column.label }}</div>
              <div class="col">
                <q-badge
                  v-if="column.badge"
                  :color="
                    column.badge.find(item => item.value === row[column.field])
                      ? column.badge.find(
                          item => item.value === row[column.field]
                        ).color
                      : 'grey'
                  "
                  >{{
                    column.badge.find(item => item.value === row[column.field])
                      ? column.badge.find(
                          item => item.value === row[column.field]
                        ).text
                      : ""
                  }}</q-badge
                >
                <span v-else>{{ row[column.field] }}</span>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <q-table
        v-else
        class="my-sticky-header-table"
        :data="data"
        :columns="columns"
        :hide-pagination="hidePagination"
        :pagination="initialPagination"
        :dense="dense"
      >
        <template v-slot:top>
          <div class="row justify-between" style="width:100%;">
            <div class="col-8">
              <q-input
                type="text"
                label="Buscar"
                style="width: 100%"
                v-model="filter"
                clearable
                v-if="filterData"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <span v-if="title" class="text-bold">
                {{ title }}
              </span>
            </div>
            <div class="col-auto">
              <q-btn
                v-if="actionButtonLabel"
                :label="actionButtonLabel"
                :color="actionButtonColor"
                :icon-right="actionButtonIcon"
                @click="actionButtonClick"
                flat
                outline
              />
            </div>
          </div>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props" @click.native="rowClick(props.row)">
            <q-td v-for="column in props.cols" :key="column.field">
              <q-badge
                v-if="column.badge"
                :color="
                  column.badge.find(
                    item => item.value === props.row[column.field]
                  )
                    ? column.badge.find(
                        item => item.value === props.row[column.field]
                      ).color
                    : 'grey'
                "
                >{{
                  column.badge.find(
                    item => item.value === props.row[column.field]
                  )
                    ? column.badge.find(
                        item => item.value === props.row[column.field]
                      ).text
                    : ""
                }}</q-badge
              >
              <span v-else-if="column.type || column.type === 'string'">{{
                formatString(
                  props.row[column.field] || "",
                  column.type,
                  null,
                  string
                )
              }}</span>
              <span v-else-if="column.type || column.type === 'date'">{{
                formatString(
                  props.row[column.field] || "",
                  column.type,
                  null,
                  currency
                )
              }}</span>
              <span v-else-if="column.type || column.type !== 'date'"
                >{{
                  formatString(
                    props.row[column.field] || 0,
                    column.type,
                    null,
                    currency
                  )
                }}
              </span>
              <span v-else>{{ props.row[column.field] }}</span>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import formatString from "@/helpers/formatString";

export default {
  props: {
    title: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    actionButtonLabel: {
      type: String,
      required: false
    },
    actionButtonIcon: {
      type: String,
      required: false
    },
    actionButtonColor: {
      type: String,
      required: false
    },
    filterData: {
      type: Boolean,
      required: false,
      default: false
    },
    gridMobile: {
      type: Boolean,
      required: false,
      default: true
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    hidePagination: {
      type: Boolean,
      required: false,
      default: false
    },
    currency: {
      type: String,
      required: false,
      default: "BRL"
    }
  },
  data() {
    return {
      cols: [],
      filter: null,
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 0,
        rowsPerPage: 500
      }
    };
  },
  computed: {
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    formatString,
    actionButtonClick() {
      this.$emit("actionButtonClick");
    },
    rowClick(row) {
      this.$emit("rowClick", row);
    }
  },
  watch: {
    filter(newValue) {
      this.$emit("filter", newValue);
    }
  }
  /*
  created: {
    upperCase() {
      this.columns.forEach(e => {
        if( e.type == "string" ) {
          cols. = 
        }
      });
    }
  }
  */
};
</script>

<style lang="scss">
.DataTable {
  .mobile {
    width: 95vw;
    .card-header {
      .q-card__section {
        padding: 2px;
      }
    }
  }

  .my-sticky-header-table {
    max-height: 85vh;
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
      background-color: #f7f7f7;
    }
    thead tr th {
      position: sticky;
      z-index: 1;
    }
    thead tr:first-child th {
      top: 0;
    }

    &.q-table--loading thead tr:last-child th {
      top: 48px;
    }
    .q-table__grid-item {
      width: 100%;
    }
  }
}
</style>
